import React, {useEffect, useState} from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const Timer = (props) => {
    const {earning, rate} = props;
    const [text, setText] = useState('');
    let amount = earning;
    useEffect(() => {
        var timer = setInterval(function() {
            amount += rate;
        setText(amount.toFixed(6))
          }, 1000);

        return () => {
            clearInterval(timer)
        };
    }, [earning, rate]);

    // Update the count down every 1 second
    

  return (
    <div>
        <Box sx={{display: 'flex', justifyContent: 'space-evenly'}}>
        <Typography> MINING:</Typography> <Typography sx={{color: 'primary.success', marginLeft: '8px'}}>${text}</Typography>
        </Box>
    </div>
  )
}

export default Timer
